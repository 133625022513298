import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormAutocompleteField from "components/ui/form/FormAutocompleteField"
import FormCheckboxField from "components/ui/form/FormCheckboxField"
import FormNumberField from "components/ui/form/FormNumberField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import React, { useState } from "react"
import * as yup from "yup"

const validationSchema = yup.object({
	name: yup
		.string()
		.min(3, "Blok nomi kamida 3 ta belgidan iborat bo'lishi kerak!")
		.required("Blok nomini kiritish majburiy!"),
	objects_id: yup.number().required("Obyektni tanlash majburiy!"),
	stage: yup
		.number()
		.required("Qavatlar sonini kiritish majburiy!"),
	room_number: yup
		.number()
		.required("Qavatdagi uylar sonini kiritish majburiy!"),
	hasBasement: yup.string().optional(),
	basementnumber: yup.number().required("Podval sonini kiritish majburiy"),
	hasmarket: yup.string().optional(),
	marketnumber: yup.number().required("Do'kon sonini kiritish majburiy"),
})

const BlockAddModal = (props) => {
	const { open, setOpen, setRefetch } = props
	const axiosPrivate = useAxiosPrivate()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const sendNotification = useNotification()

	const formik = useFormik({
		initialValues: {
			name: "",
			objects_id: "",
			stage: 0,
			room_number: 0,
			hasBasement: "0",
			basementnumber: 0,
			hasmarket: "0",
			marketnumber: 0
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setIsSubmitting(true)
				const response = await axiosPrivate.post(
					"/admin/block/store",
					JSON.stringify(values),
					{ headers: { "Content-Type": "application/json" } }
				)
				if (response.data && response.data.status) {
					sendNotification({
						msg: `Yangi "${values.name}" muvaffaqiyatli yaratildi!`,
						variant: "success"
					})
					setIsSubmitting(false)
					setRefetch(true)
					handleClose()
				}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})

	const handleClose = () => {
		formik.resetForm()
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>Blok yaratish</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid
						container
						spacing={{ xs: 2, sm: 3, lg: 3 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, lg: 12 }}
					>
						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormTextField
								delay={0.1}
								label="Blok nomi"
								fieldName="name"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormNumberField
								delay={0.2}
								label="Qavatlar soni"
								fieldName="stage"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormNumberField
								delay={0.2}
								label="Qavatdagi uylar soni"
								fieldName="room_number"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormAutocompleteField
								delay={0.3}
								fieldName="objects_id"
								label="Obyekt nomi"
								formik={formik}
								path={"/dictionary/objects2"}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormCheckboxField
								delay={0.5}
								label="Yerto'la bilan"
								fieldName="hasBasement"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormNumberField
								delay={0.5}
								label="Yerto'la soni"
								fieldName="basementnumber"
								formik={formik}
								disabled={formik.values.hasBasement==="0"}
							/>
						</Grid>
						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormCheckboxField
								delay={0.5}
								label="Do'kon bilan"
								fieldName="hasmarket"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} lg={4} sm={6} xs={12}>
							<FormNumberField
								delay={0.5}
								label="Do'kon soni"
								fieldName="marketnumber"
								formik={formik}
								disabled={formik.values.hasmarket==="0"}
							/>
						</Grid>
						
						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								delay={0.5}
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default BlockAddModal
