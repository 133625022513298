function thousandsSeparator(str) {
  return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  // return srt?.toString().replace([0-9](?=(?:[0-9]{3})+(?![0-9])))
}

function phoneNumberSeparator(str) {
  var cleaned = ('' + str).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let match2 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  let match3 = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{4})$/);

  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  } else if (match3) {
    return (
      '(' + match3[1] + ') ' + match3[2] + '-' + match3[3] + '-' + match3[4]
    );
  } else if (match2) {
    return '(' + match2[1] + ') ' + match2[2] + '-' + match2[3];
  } else {
    return str;
  }
}

export { thousandsSeparator, phoneNumberSeparator };
