/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Button } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { LinearProgress, Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import BaseTableHead from './parts/BaseTableHead';
import setTableCellType from 'utils/setTableCellType';
import InfoActionButton from '../action-buttons/InfoActionButton';
import TableColumnToggleDrawer from 'components/TableColumnToggleDrawer';
import useColumnToggle from 'hooks/useColumnToggle';
import CONTRACT_STATUS_TYPE from 'shared/contractStatusTypeList';
import addActiveInTableCell from 'utils/addActiveInTableCell';

export default function PaymentConfirmationTable({
  dataPath = '',
  dataPathSend = '',
  tableName = '',
  emitTableColumns = {},
  emitRefetch = {},
  isFilterable = false,
  actionInfo = null,
  actionCustomInfo = null,
}) {
  const axiosPrivate = useAxiosPrivate();
  const headCells = [
    { code: 'name', label: 'Shartnoma nomi' },
    { code: 'contract', label: 'Mijoz' },
    { code: 'staff', label: 'Xodim' },
    { code: 'date', label: 'Sana' },
    { code: 'type_id', label: "To'lov turi" },
    { code: 'sum', label: 'Summa' },
  ];
  const columns = [
    { code: 'contract', type: 'nested', childStr: 'name' },
    { code: 'contract', type: 'grandson', childStr: 'custom_fullname' },
    { code: 'staff', type: 'nested', childStr: 'name' },
    { code: 'date', type: 'date' },
    { code: 'types', type: 'nested', childStr: 'name' },
    { code: 'sum', type: 'priceCurrency' },
  ];
  const { tableData, tableHeadCells, tableColumnCells, setTableHeadCells } =
    useColumnToggle(
      tableName,
      addActiveInTableCell(headCells),
      addActiveInTableCell(columns)
    );
  const [queryPath, setQueryPath] = React.useState('');
  const [, setHasError] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [allSelected, setAllSelected] = React.useState(false);
  const [totalSum, setTotalSum] = React.useState(0);
  const [totalValute, setTotalValute] = React.useState(0);
  const {
    error,
    data: rows,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: dataPath + 'Key',
    queryFn: async function () {
      const response = await axiosPrivate.get(queryPath);
      return response?.data;
    },
    onSettled: () => {
      emitRefetch.setRefetch(false);
    },
    enabled: false,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    (searchParams.get('page') && parseInt(searchParams.get('page'))) || 1
  );

  React.useEffect(() => {
    if (queryPath && queryPath.length > 0) {
      refetch();
    }
  }, [queryPath]);

  React.useEffect(() => {
    createQueryPath();
  }, [searchParams]);

  React.useEffect(() => {
    if (emitRefetch && emitRefetch.refetch) {
      refetch();
    }
  }, [emitRefetch.refetch]);

  React.useEffect(() => {
    let newValue = [];
    if (allSelected) {
      newValue = rows.data.map((x) => x.id);
    }
    setSelectedItems(newValue);
    calcTotalSums(newValue);
  }, [allSelected]);

  const calcTotalSums = (newSelections) => {
    let tSum = 0;
    let tValute = 0;
    for (const row of rows?.data || []) {
      if (newSelections.includes(row.id)) {
        if (row.isvalute === '0') {
          tSum += parseInt(row.sum);
        } else {
          tValute += parseInt(row.sum);
        }
      }
    }
    setTotalSum(tSum);
    setTotalValute(tValute);
  };

  const handleRowClick = (item) => {
    const index = selectedItems.indexOf(item);
    let newSelectedItems;

    if (index === -1) {
      // Item not in the selected list, so add it
      newSelectedItems = [...selectedItems, item];
    } else {
      // Item is already selected, so remove it
      newSelectedItems = selectedItems.filter(
        (selectedItem) => selectedItem !== item
      );
    }
    setSelectedItems(newSelectedItems);
    calcTotalSums(newSelectedItems);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  const handleOnSubmit = () => {
    const result = window.confirm('Chindan ham tasdiqlamoqchimisiz?');
    let urlString = '';

    if (result) {
      for (let i = 0; i < selectedItems.length; i++) {
        if (urlString.length > 0) {
          urlString += '&';
        }
        urlString += `ids[${i}]=${selectedItems[i]}`;
      }
      setAllSelected(false);
      setSelectedItems([]);
      axiosPrivate.get(dataPathSend + '?' + urlString).then((data) => {
        emitRefetch.setRefetch(true);
      });
    }
  };

  const createQueryPath = () => {
    let newQueryPath = `/${dataPath}`;
    let localSearchParams = Object.fromEntries([...searchParams]);
    Object.keys(localSearchParams).forEach((item, index) => {
      if (index === 0) {
        newQueryPath += `?${item}=${localSearchParams[item]}`;
      } else {
        newQueryPath += `&${item}=${localSearchParams[item]}`;
      }
    });
    setQueryPath(newQueryPath);
    if (!isNaN(localSearchParams.page)) {
      setPage(parseInt(localSearchParams.page));
    }
  };

  return (
    <Box className='base-table w-full h-full flex flex-col'>
      {selectedItems.length > 0 && (
        <div className='text-base-color text-xl font-medium m-3'>
          {selectedItems.length} ta tanlandi
          <span className='sum m-3'>
            So'mda: <CurrencyFormat value={totalSum} suffix={' UZS'} />
          </span>
          <span className='sum m-3'>
            Valyuta: <CurrencyFormat value={totalValute} suffix={' $'} />
          </span>
          <Button
            color='success'
            variant='contained'
            type='submit'
            initial='hidden'
            animate='show'
            viewport={{ once: true, amount: 0.25 }}
            className={'!ml-1'}
            onClick={handleOnSubmit}
          >
            Tanlanganlarni tasdiqlash
          </Button>
        </div>
      )}

      <TableContainer className='flex-auto h-full'>
        <Table
          stickyHeader
          sx={{ minWidth: 750, height: 'max-content' }}
          aria-labelledby='tableTitle'
        >
          {tableHeadCells && tableColumnCells && (
            <React.Fragment>
              <BaseTableHead
                headCells={tableHeadCells}
                isFilterable={isFilterable}
                extra_column={true}
                hasSelect={true}
                allSelected={allSelected}
                setAllSelected={setAllSelected}
              />
              {isLoading || isFetching ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : isError ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <div className='flex flex-col items-center'>
                        {error?.response?.data?.message && (
                          <span className='text-red-600 font-medium'>
                            {error?.response?.data?.message}
                          </span>
                        )}
                        {error?.response?.data?.details &&
                          error?.response?.data?.details[0]?.message && (
                            <div>
                              <span className='text-red-600 font-medium'>
                                Error message:{' '}
                              </span>
                              <span>
                                {error?.response?.data?.details[0]?.message}
                              </span>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : rows && rows.data && rows.data.length > 0 ? (
                <React.Fragment>
                  <TableBody className='overflow-hidden'>
                    {rows.data.map((row, rowIndex) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={'row-' + rowIndex}
                          className={
                            row?.status === CONTRACT_STATUS_TYPE.COMPLETE.code
                              ? 'bg-cyan-200'
                              : row?.status === CONTRACT_STATUS_TYPE.CANCEL.code
                              ? 'bg-red-300'
                              : ''
                          }
                        >
                          <TableCell align={'left'} sx={{ maxWidth: 10 }}>
                            <Checkbox
                              id={'checkbox' + row.id}
                              checked={selectedItems.includes(row.id)}
                              onChange={(event) => {
                                handleRowClick(row.id);
                              }}
                            />
                          </TableCell>

                          {tableColumnCells.map((column, columnIndex) => (
                            <TableCell
                              align={
                                tableHeadCells[columnIndex]?.numeric
                                  ? 'right'
                                  : 'left'
                              }
                              key={'column-' + columnIndex}
                            >
                              {column?.type
                                ? setTableCellType(
                                    column,
                                    row[column.code],
                                    row
                                  )
                                : row[column?.code]}
                            </TableCell>
                          ))}

                          <TableCell align="right">
                            <InfoActionButton link={`/view/${row.contract_id}`} />
													</TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </React.Fragment>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <span className='no-data-found-wrapper'>
                        <i className='bi bi-exclamation-octagon text-xl mr-1' />{' '}
                        Ma'lumotlar topilmadi!
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
      {rows && rows.data && rows.data.length > 0 && (
        <div className='p-3 mb-2 mt-4 flex items-center justify-center rounded-lg my-shadow-2'>
          <Pagination
            count={Math.ceil(rows.total / rows.per_page) || 1}
            page={page}
            onChange={handleChangePage}
            variant='outlined'
            color='primary'
            showFirstButton
            showLastButton
          />
        </div>
      )}

      {emitTableColumns && Object.keys(emitTableColumns).length === 2 && (
        <TableColumnToggleDrawer
          open={emitTableColumns.open}
          setOpen={emitTableColumns.setOpen}
          tableData={tableData}
          setTableHeadCells={setTableHeadCells}
        />
      )}
    </Box>
  );
}
