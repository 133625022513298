const HOME_TYPE = {
	ACTIVE: {
		code: "1",
		label: "Bo'sh"
	},
	TIME: {
		code: "2",
		label: "Band qilingan"
	},
	ORDERED: {
		code: "3",
		label: "Sotilgan"
	},
	DISABLED: {
		code: "4",
		label: "Nofaol"
	}
}

module.exports = HOME_TYPE
