import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import NavBarLinkItem from "components/navbar-link/NavBarLinkItem"
import { motion } from "framer-motion"
import { fade } from "utils/motion"
import { Button, IconButton } from "@mui/material"
import BaseTooltip from "./ui/tooltips/BaseTooltip"
import useAuth from "hooks/useAuth"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import getLabelByTypeList from "utils/getLabelByTypeList"

const SidebarPanel = ({ sideBarToggle, setSideBarToggle }) => {
	const [{ user, logout }] = useAuth()

	const getLogoLink = () => {
		if (user && user?.user?.role) {
			if (user?.user?.role === ROLE_TYPE_LIST.ADMIN.code) {
				return "/admin/dashboard"
			} else if (user?.user?.role === ROLE_TYPE_LIST.ACCOUNTER.code) {
				return "/accounter/payment"
			} else if (user?.user?.role === ROLE_TYPE_LIST.MANAGER.code) {
				return "/manager/dashboard"
			} else if (user?.user?.role === ROLE_TYPE_LIST.OPERATOR.code) {
				return "/operator/lead"
			} else if (user?.user?.role === ROLE_TYPE_LIST.CASHER.code) {
				return "/kassir/accepts"
			}
		}
		return "#"
	}

	return (
		<div className="sidebar-panel-wrapper my-shadow-2 overflow-y-auto h-full">
			<div className="sidebar-panel-header h-[100px] flex items-center justify-center xs:flex-row mt-3 p-3">
				<Link to={getLogoLink()} className="no-underline">
					<motion.img
						variants={fade({
							direction: "left",
							positionHiddenX: "-30px",
							duration: 0.5
						})}
						initial="hidden"
						animate="show"
						src={require("assets/images/logo/logo-black.png")}
						alt="logo.png"
						className="w-[120px] xs:m-0 mx-auto"
					/>
				</Link>
				<div className="md:hidden close-btn-wrapper">
					<IconButton
						variant="onlyIcon"
						color="primary"
						onClick={() => setSideBarToggle(false)}
					>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</div>

			<ul className="sidebar-links-wrapper mt-2 p-3">
				{user?.user?.role === ROLE_TYPE_LIST.CASHER.code && (
					<Fragment>
						<NavBarLinkItem
							linkData={{
								path: "kassir/accepts",
								title: "To'lov tasdiqlash"
							}}
							iconName="bi bi-cash-coin"
							delay={0.1}
						/>
						
					</Fragment>
				)}
				{user?.user?.role === ROLE_TYPE_LIST.ADMIN.code && (
					<Fragment>
						<NavBarLinkItem
							linkData={{
								path: "admin/dashboard",
								title: "Bosh sahifa"
							}}
							iconName="bi bi-house"
							delay={0}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/homes",
								title: "Uylar"
							}}
							iconName="bi bi-houses"
							delay={0.1}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/order",
								title: "Band qilinganlar"
							}}
							iconName="bi bi-stopwatch"
							delay={0.2}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/object",
								title: "Obyektlar"
							}}
							iconName="bi bi-houses"
							delay={0.3}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/shaxmatka",
								title: "Shaxmatka"
							}}
							iconName="bi bi-grid-3x3-gap"
							delay={0.4}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/contract",
								title: "Shartnoma"
							}}
							iconName="bi bi-clipboard-check"
							delay={0.5}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/payment",
								title: "To'lov"
							}}
							iconName="bi bi-cash-coin"
							delay={0.6}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/accepts",
								title: "To'lov tasdiqlash"
							}}
							iconName="bi bi-cash-coin"
							delay={0.6}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/currency",
								title: "Valyuta"
							}}
							iconName="bi bi-coin"
							delay={0.7}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/report",
								title: "Hisobot"
							}}
							iconName="bi bi-calculator"
							delay={0.7}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/client",
								title: "Mijozlar"
							}}
							iconName="bi bi-people"
							delay={0.8}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/lead",
								title: "Lidlar"
							}}
							iconName="bi bi-people"
							subIconName="bi bi-award-fill"
							delay={0.9}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/news",
								title: "Yangiliklar"
							}}
							iconName="bi bi-newspaper"
							delay={1.0}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/arrear",
								title: "Qarzdorlik"
							}}
							iconName="bi bi-cash-stack"
							delay={1.1}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/message",
								title: "SMS"
							}}
							iconName="bi bi-chat-right-text"
							delay={1.2}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/change",
								title: "O'zgarishlar"
							}}
							iconName="bi bi-arrow-repeat"
							delay={1.3}
						/>
						<NavBarLinkItem
							linkData={{
								path: "admin/payment-change",
								title: "To'lov o'zgarishlari"
							}}
							iconName="bi bi-cash-stack"
							delay={1.4}
						/>
					</Fragment>
				)}

				{user?.user?.role === ROLE_TYPE_LIST.MANAGER.code && (
					<Fragment>
						<NavBarLinkItem
							linkData={{
								path: "manager/dashboard",
								title: "Bosh sahifa"
							}}
							iconName="bi bi-house"
							delay={0}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/homes",
								title: "Uylar"
							}}
							iconName="bi bi-houses"
							delay={0.1}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/order",
								title: "Band qilinganlar"
							}}
							iconName="bi bi-stopwatch"
							delay={0.2}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/shaxmatka",
								title: "Shaxmatka"
							}}
							iconName="bi bi-grid-3x3-gap"
							delay={0.3}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/contract",
								title: "Shartnoma"
							}}
							iconName="bi bi-clipboard-check"
							delay={0.4}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/payment",
								title: "To'lov"
							}}
							iconName="bi bi-cash-coin"
							delay={0.5}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/client",
								title: "Mijozlar"
							}}
							iconName="bi bi-people"
							delay={0.6}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/lead",
								title: "Lidlar"
							}}
							iconName="bi bi-people"
							subIconName="bi bi-award-fill"
							delay={0.7}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/news",
								title: "Yangiliklar"
							}}
							iconName="bi bi-newspaper"
							delay={0.8}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/arrear",
								title: "Qarzdorlik"
							}}
							iconName="bi bi-cash-stack"
							delay={0.9}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/message",
								title: "SMS"
							}}
							iconName="bi bi-chat-right-text"
							delay={1}
						/>

						<NavBarLinkItem
							linkData={{
								path: "manager/change",
								title: "O'zgarishlar"
							}}
							iconName="bi bi-arrow-repeat"
							delay={1.1}
						/>
					</Fragment>
				)}

				{user?.user?.role === ROLE_TYPE_LIST.ACCOUNTER.code && (
					<Fragment>
						<NavBarLinkItem
							linkData={{
								path: "accounter/payment",
								title: "To'lov"
							}}
							iconName="bi bi-cash-coin"
							delay={0}
						/>

						<NavBarLinkItem
							linkData={{
								path: "accounter/change",
								title: "O'zgarishlar"
							}}
							iconName="bi bi-arrow-repeat"
							delay={0.1}
						/>
					</Fragment>
				)}

				{user?.user?.role === ROLE_TYPE_LIST.OPERATOR.code && (
					<Fragment>
						<NavBarLinkItem
							linkData={{
								path: "operator/lead",
								title: "Lidlar"
							}}
							iconName="bi bi-people"
							subIconName="bi bi-award-fill"
							delay={0}
						/>
					</Fragment>
				)}
			</ul>

			<ul className="sidebar-links-wrapper p-3">
				{user?.user?.role === ROLE_TYPE_LIST.ADMIN.code && (
					<Fragment>
						<NavBarLinkItem
							linkData={{
								path: "admin/block",
								title: "Bloklar"
							}}
							iconName="bi bi-buildings"
							delay={1.5}
						/>

						<NavBarLinkItem
							linkData={{
								path: "admin/settings",
								title: "Sozlash"
							}}
							iconName="bi bi-gear"
							delay={1.5}
						/>

						{/* <NavBarLinkAccordion
							linkData={{
								path: "admin/settings",
								title: "Sozlash"
							}}
							accordionItems={[
								{
									id: 1,
									path: "company",
									title: "Kompaniyalar"
								},
								{
									id: 2,
									path: "staff",
									title: "Xodimlar"
								},
								{
									id: 3,
									path: "home",
									title: "Uy sozlamalari"
								}
							]}
							iconName="bi bi-gear"
							delay={1.1}
						/> */}
					</Fragment>
				)}

				<NavBarLinkItem
					linkData={{
						path: "profile",
						title: "Profil"
					}}
					iconName="bi bi-person"
					delay={0}
					customTextComponent={
						<div className="flex flex-col items-start leading-4 ml-2">
							<span className="text-base-color text-[0.9rem]">
								{user?.user?.name}
							</span>
							<span className="text-gray-400 text-sm leading-4">
								{user?.user?.role &&
									getLabelByTypeList(ROLE_TYPE_LIST, user?.user?.role)}
							</span>
						</div>
					}
				/>

				<Button
					variant="outlined"
					onClick={logout}
					className="w-full flex items-center"
				>
					<i className="bi bi-box-arrow-left text-lg mr-2" />
					<span className="text-[0.9rem]">Chiqish</span>
				</Button>
			</ul>

			<div className="toggle-btn-wrapper absolute top-1/2">
				{sideBarToggle ? (
					<BaseTooltip
						enterDelay={2000}
						leaveTouchDelay={0}
						title={
							<div>
								Yopish uchun{" "}
								<code className="bg-white p-0.5 text-base-color text-lg rounded-sm">
									[
								</code>{" "}
								ni bosing
							</div>
						}
						arrow={true}
						placement="right"
					>
						<IconButton
							variant="toggle"
							onClick={() => setSideBarToggle(false)}
						>
							<i className="bi bi-caret-left text-base-color text-xl leading-5" />
						</IconButton>
					</BaseTooltip>
				) : (
					<BaseTooltip
						enterDelay={2000}
						leaveTouchDelay={0}
						title={
							<div>
								Ochish uchun{" "}
								<code className="bg-white p-0.5 text-base-color text-lg rounded-sm">
									]
								</code>{" "}
								ni bosing
							</div>
						}
						arrow={true}
						placement="right"
					>
						<IconButton variant="toggle" onClick={() => setSideBarToggle(true)}>
							<i className="bi bi-caret-right text-base-color text-xl leading-5" />
						</IconButton>
					</BaseTooltip>
				)}
			</div>
		</div>
	)
}

export default SidebarPanel
