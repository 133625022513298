/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid } from '@mui/material';
import BackButton from 'components/ui/BackButton';
import PaymentHistoryTable from 'components/ui/tables/PaymentHistoryTable';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useTopPanel from 'hooks/useTopPanel';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import CLIENT_TYPE from 'shared/clientTypeList';
import REPAIR_TYPE from 'shared/repairTypeList';
import getLabelByTypeList from 'utils/getLabelByTypeList';
import PhoneFormat from 'components/ui/text-formats/PhoneFormat';
import ContractCancellationModal from './ContractCancellationModal';
import SuccessTooltip from 'components/ui/tooltips/SuccessTooltip';
import InfoTooltip from 'components/ui/tooltips/InfoTooltip';
import BaseTooltipCustomWidth from 'components/ui/tooltips/BaseTooltipCustomWidth';
import useAuth from 'hooks/useAuth';
import ROLE_TYPE_LIST from 'shared/roleTypeList';
import UploadContractPaymentsListAndHistory from './UploadContractPaymentsListAndHistory';
import ContractPaymentSimpleListModal from './ContractPaymentSimpleListModal';
import PaymentAddModal from '../../accounter/payment/PaymentAddModal';
import ImagePreviewDialog from 'components/ui/dialogs/ImagePreviewDialog';

const ContractView2 = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [{ user }] = useAuth();
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalLeftByMonth, setTotalLeftByMonth] = useState(0);
  const [cancellationOpen, setCancellationOpen] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [uploadPaymentsListOpen, setUploadPaymentsListOpen] = useState(false);
  const [uploadPaymentsHistoryOpen, setUploadPaymentsHistoryOpen] =
    useState(false);
  const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
    useState(false);
  const [contractTypes, setContractTypes] = useState({});
  const { setComponent } = useTopPanel();

  const [contractQuery, paymentsListQuery] = useQueries([
    {
      queryKey: 'contractSingle',
      queryFn: async function () {
        const response = await axiosPrivate.get(`/admin/contract/edit/${id}`);
        const response2 = await axiosPrivate.get('dictionary/contracttypes');

        const typesObject = {};
        for (const item of response2.data.data) {
          typesObject[item.id] = item.name;
        }
        setContractTypes(typesObject);

        return response.data.data;
      },
      onSuccess: (data) => {},
      enabled: !hasError && !!id,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: 'contractPaymentList',
      queryFn: async function () {
        const response = await axiosPrivate.get(`/admin/contract/list/${id}`);
        return response.data.data;
      },
      onSuccess: (data) => {
        let leftTotal = 0;
        data.forEach((item, index) => {
          if (moment(item.payment_date, 'YYYY-MM-DD').isBefore(moment())) {
            leftTotal += +item.left;
          }
        });
        setTotalLeftByMonth(leftTotal);
      },
      enabled: !hasError && !!id,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  useEffect(() => {
    setComponent(
      <div className='flex flex-row items-center'>
        <BackButton />
        <div className='text-base-color text-xl font-medium flex flex-row'>
          Shartnoma №{' '}
          <BaseTooltipCustomWidth
            arrow={true}
            placement='bottom'
            enterDelay={1000}
            leaveTouchDelay={0}
            title={contractQuery?.data?.name}
            width={'800px'}
            fontSize={'1rem'}
          >
            <span className='text-line-1 max-w-[800px] ml-1'>
              {contractQuery?.data?.name}
            </span>
          </BaseTooltipCustomWidth>
          <span className='text-line-1 max-w-[800px] ml-1'>
            Turi: {contractTypes[contractQuery?.data?.type]}
          </span>
        </div>
      </div>
    );
  }, [contractQuery?.data]);

  return (
    <div className='component-add-edit-wrapper mx-4'>
      {contractQuery.isLoading || contractQuery.isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        contractQuery?.data && (
          <Fragment>
            <div className='component-add-edit-header mt-3'>
              <Grid container spacing={2} columns={{ xs: 12, sm: 12, lg: 12 }}>
                <Grid item={true} lg={2} md={4} sm={6} xs={12}>
                  <Button
                    color='secondary'
                    variant='contained'
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2'>
                      <span className='text-lg leading-5 mb-1'>
                        Shartnoma summasi
                      </span>
                      <span className='text-sm'>
                        <CurrencyFormat
                          value={contractQuery?.data?.sum}
                          suffix={
                            contractQuery?.data?.isvalute === '1'
                              ? ' $'
                              : ' UZS'
                          }
                        />
                      </span>
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={4} sm={6} xs={12}>
                  <Button
                    color='info'
                    variant='contained'
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2'>
                      <span className='text-lg leading-5 mb-1'>
                        Boshlang'ich to'lov
                      </span>
                      <span className='text-sm'>
                        <CurrencyFormat
                          value={contractQuery?.data?.start_price}
                          suffix={
                            contractQuery?.data?.isvalute === '1'
                              ? ' $'
                              : ' UZS'
                          }
                        />
                      </span>
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={4} sm={6} xs={12}>
                  <Button
                    color='warning'
                    variant='contained'
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2'>
                      <span className='text-lg leading-5 mb-1'>
                        1 m<sup>2</sup> uchun to'lov miqdori
                      </span>
                      <span className='text-sm'>
                        <CurrencyFormat
                          value={contractQuery?.data?.price}
                          suffix={
                            contractQuery?.data?.isvalute === '1'
                              ? ' $'
                              : ' UZS'
                          }
                        />
                      </span>
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={4} sm={6} xs={12}>
                  <Button
                    color='success'
                    variant='contained'
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2'>
                      <span className='text-lg leading-5 mb-1'>
                        Chegirma miqdori
                      </span>
                      <span className='text-sm'>
                        <CurrencyFormat
                          value={contractQuery?.data?.discount}
                          suffix={
                            contractQuery?.data?.isvalute === '1'
                              ? ' $'
                              : ' UZS'
                          }
                        />
                      </span>
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={4} sm={6} xs={12}>
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{
                      backgroundColor: '#1f52d8',
                      '&:hover': {
                        backgroundColor: '#173da1',
                      },
                    }}
                    className='!h-full'
                  >
                    <div className='flex flex-col p-2'>
                      <span className='text-lg leading-5 mb-1'>
                        Oylik qarzdorlik
                      </span>
                      <span className='text-sm'>
                        {totalLeftByMonth ? (
                          <CurrencyFormat
                            value={totalLeftByMonth}
                            allowNegative={true}
                            suffix={
                              contractQuery?.data?.isvalute === '1'
                                ? ' $'
                                : ' UZS'
                            }
                          />
                        ) : (
                          'Mavjud emas'
                        )}
                      </span>
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={4} sm={6} xs={12}>
                  <Button
                    color='error'
                    variant='contained'
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2'>
                      <span className='text-lg leading-5 mb-1'>
                        Qolgan to'lov miqdori
                      </span>
                      <span className='text-sm'>
                        <CurrencyFormat
                          value={contractQuery?.data?.leftsum}
                          allowNegative={true}
                          suffix={' UZS'}
                        />
                      </span>
                      <span className='text-sm'>
                        <CurrencyFormat
                          value={contractQuery?.data?.leftvalute}
                          allowNegative={true}
                          suffix={' $'}
                        />
                      </span>
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </div>
            {contractQuery?.data?.comment && (
              <div className='contract-comment my-shadow-2 rounded-lg p-4 w-full mt-4 mb-1'>
                <div>
                  <span className='font-medium text-base-color'>
                    Kommentariya:
                  </span>{' '}
                  {contractQuery?.data?.comment}
                </div>
              </div>
            )}
            <div className='component-add-edit-body'>
              <div className='py-3 flex flex-row'>
                <div className='base-data w-full'>
                  <div className='home-data flex items-center justify-between my-shadow-2 rounded-lg p-4 w-full mb-4'>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Obyekt nomi:
                      </span>
                      <span className='data-item-value text-base'>
                        {contractQuery?.data?.homes?.blocks?.objects?.name}
                      </span>
                    </div>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Blok nomi:
                      </span>
                      <span className='data-item-value text-base'>
                        {contractQuery?.data?.homes?.blocks?.name}
                      </span>
                    </div>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Xonadon raqami:
                      </span>
                      <span className='data-item-value text-base'>
                        {contractQuery?.data?.homes?.number}
                      </span>
                    </div>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Qavati:
                      </span>
                      <span className='data-item-value text-base'>
                        {contractQuery?.data?.homes?.stage}
                      </span>
                    </div>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Xonalar soni:
                      </span>
                      <span className='data-item-value text-base'>
                        {contractQuery?.data?.homes?.rooms}
                      </span>
                    </div>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Maydoni:
                      </span>
                      <span className='data-item-value text-base'>
                        {contractQuery?.data?.homes?.square} m<sup>2</sup>
                      </span>
                    </div>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Ta'mir holati:
                      </span>
                      <span className='data-item-value text-base'>
                        {getLabelByTypeList(
                          REPAIR_TYPE,
                          contractQuery?.data?.isrepaired
                        )}
                      </span>
                    </div>
                    <div className='home-data-item flex flex-col'>
                      <span className='data-item-title text-base font-medium text-base-color'>
                        Uy plani:
                      </span>
                      <span className='data-item-value text-base'>
                        <Button
                          type='button'
                          variant='contained'
                          className='!-mb-[10px] !w-[100px]'
                          disabled={
                            !(
                              contractQuery?.data?.homes?.plan &&
                              contractQuery?.data?.homes?.plan?.link
                            )
                          }
                          onClick={() => setOpenHomeLayoutImageDialog(true)}
                        >
                          <i className='bi bi-image text-lg' />
                        </Button>
                      </span>
                    </div>
                  </div>

                  <div className='client-data flex items-center my-shadow-2 rounded-lg p-4 w-full'>
                    {contractQuery?.data?.custom?.client_type ===
                    CLIENT_TYPE.PHYSICAL.code ? (
                      <Grid
                        container
                        spacing={{ xs: 2, sm: 3, mdm: 3, lg: 3 }}
                        rowSpacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      >
                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Mijoz:
                            </span>
                            <span className='data-item-value text-base font-medium underline'>
                              {contractQuery?.data?.custom?.surname}{' '}
                              {contractQuery?.data?.custom?.name}{' '}
                              {contractQuery?.data?.custom?.middlename}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Tug'ilgan sanasi:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.birthday
                                ? moment(
                                    contractQuery?.data?.custom?.detail
                                      ?.birthday
                                  ).format('DD/MM/YYYY')
                                : ''}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Telefon raqami:
                            </span>
                            <span className='data-item-value text-base flex flex-col'>
                              <PhoneFormat
                                value={contractQuery?.data?.custom?.phone}
                              />
                              <PhoneFormat
                                value={contractQuery?.data?.custom?.phone2}
                              />
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Passport seriya raqami:
                            </span>
                            <span className='data-item-value text-base'>
                              {
                                contractQuery?.data?.custom?.detail
                                  ?.passport_series
                              }
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Passport berilgan sana:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.issue
                                ? moment(
                                    contractQuery?.data?.custom?.detail?.issue
                                  ).format('DD/MM/YYYY')
                                : ''}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Passport berilgan joy:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.authority}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Yashash manzili:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.home}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Ish joyi:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.work_place}
                            </span>
                          </div>
                        </Grid>
                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Sana:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.date
                                ? moment(contractQuery?.data?.date).format(
                                    'DD/MM/YYYY'
                                  )
                                : ''}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                    {contractQuery?.data?.custom?.client_type ===
                    CLIENT_TYPE.LEGAL.code ? (
                      <Grid
                        container
                        spacing={{ xs: 2, sm: 3, mdm: 3, lg: 3 }}
                        rowSpacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      >
                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Tashkilot nomi:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.name}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Telefon raqami:
                            </span>
                            <span className='data-item-value text-base'>
                              <PhoneFormat
                                value={contractQuery?.data?.custom?.phone}
                              />
                              <PhoneFormat
                                value={contractQuery?.data?.custom?.phone2}
                              />
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Tashkilot manzili:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.home}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Tashkilot hisob raqami:
                            </span>
                            <span className='data-item-value text-base'>
                              {
                                contractQuery?.data?.custom?.detail
                                  ?.account_number
                              }
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Tashkilot STIR(ИНН):
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.inn}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Tashkilot MFO:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.mfo}
                            </span>
                          </div>
                        </Grid>

                        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                          <div className='home-data-item flex flex-col'>
                            <span className='data-item-title text-base font-medium text-base-color'>
                              Tashkilot OKED:
                            </span>
                            <span className='data-item-value text-base'>
                              {contractQuery?.data?.custom?.detail?.oked}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className='contract-staff-details my-shadow-2 rounded-lg p-4 w-full mt-4 mb-1 border border-base-color bg-base-color-active-deprecated-f-30'>
                    <div>
                      <span className='font-medium text-base-color'>
                        Shartnoma tuzgan mas'ul xodim:
                      </span>{' '}
                      {contractQuery?.data?.staff?.name}
                    </div>
                  </div>

                  <div className='mt-4 mb-6'>
                    {id && (
                      <Fragment>
                        <div className='payment-history-title my-2 text-lg font-medium text-base-color'>
                          To'lov tarixi
                        </div>
                        <PaymentHistoryTable
                          dataPath={`dictionary/paymentscontract/${id}`}
                        />
                      </Fragment>
                    )}
                  </div>
                </div>
                <div className='actions-box w-[280px] ml-6'>
                  <div className='p-4 my-shadow-2 rounded-lg flex items-center justify-center'>
                    <InfoTooltip
                      arrow={true}
                      placement='top'
                      title="Ma'lumotlarni yangilash"
                    >
                      <Button
                        variant='action'
                        color='info'
                        onClick={contractQuery.refetch}
                      >
                        <i className='bi bi-arrow-repeat' />
                      </Button>
                    </InfoTooltip>
                    <SuccessTooltip
                      arrow={true}
                      placement='top'
                      title='Shartnomani yuklab olish'
                    >
                      <Link
                        to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractQuery?.data?.id}`}
                        className='no-underline'
                      >
                        <Button
                          variant='action'
                          color='success'
                          className='!mx-4'
                        >
                          <i className='bi bi-download' />
                        </Button>
                      </Link>
                    </SuccessTooltip>
                  </div>

                  <Button
                    color='primary'
                    variant='outlined'
                    fullWidth
                    className='!py-2 !my-2 !h-auto'
                    onClick={() => setOpen(true)}
                  >
                    <div className='flex flex-col'>
                      <i className='bi bi-bookmarks text-2xl' />
                      <span className='text-base'>To'lov grafigi</span>
                    </div>
                  </Button>

                </div>
              </div>
            </div>
          </Fragment>
        )
      )}
      {open && (
        <ContractPaymentSimpleListModal
          open={open}
          setOpen={setOpen}
          data={contractQuery?.data}
          paymentsListQuery={paymentsListQuery}
        />
      )}

      {openHomeLayoutImageDialog &&
        contractQuery?.data?.homes?.plan &&
        contractQuery?.data?.homes?.plan?.link && (
          <ImagePreviewDialog
            open={openHomeLayoutImageDialog}
            setOpen={setOpenHomeLayoutImageDialog}
            url={contractQuery?.data?.homes?.plan?.link}
          />
        )}

      {cancellationOpen && (
        <ContractCancellationModal
          open={cancellationOpen}
          setOpen={setCancellationOpen}
          data={contractQuery?.data}
          refetch={contractQuery.refetch}
        />
      )}

      {uploadPaymentsListOpen && (
        <UploadContractPaymentsListAndHistory
          open={uploadPaymentsListOpen}
          setOpen={setUploadPaymentsListOpen}
          dialogData={{
            title: "Shartnoma to'lov grafigini yuklash",
            successMsg:
              "Ushbu shartnomaga to'lov grafigi muvaffaqiyatli yuklandi!",
            path: `paymentlist/${contractQuery?.data?.id}`,
            postValueKey: 'list',
          }}
          options={[
            {
              label: "To'lov summasi",
              code: 'sum',
              disabled: false,
            },
            {
              label: "To'lov sanasi",
              code: 'date',
              disabled: false,
            },
          ]}
        />
      )}

      {uploadPaymentsHistoryOpen && (
        <UploadContractPaymentsListAndHistory
          open={uploadPaymentsHistoryOpen}
          setOpen={setUploadPaymentsHistoryOpen}
          dialogData={{
            title: "Shartnomaga qilingan to'lovlarni yuklash",
            successMsg:
              "Ushbu shartnomaga qilingan to'lovlar muvaffaqiyatli yuklandi!",
            path: `payment/${contractQuery?.data?.id}`,
            postValueKey: 'payments',
          }}
          options={[
            {
              label: "To'lov summasi",
              code: 'sum',
              disabled: false,
            },
            {
              label: "To'lov sanasi",
              code: 'date',
              disabled: false,
            },
            {
              label: "To'lov turi",
              code: 'type_id',
              disabled: false,
            },
          ]}
        />
      )}

      {openPaymentModal && (
        <PaymentAddModal
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          refetch={contractQuery.refetch}
          data={contractQuery?.data}
        />
      )}
    </div>
  );
};

export default ContractView2;
