import useTopPanel from "hooks/useTopPanel"
import { useEffect } from "react"

const Profile = () => {

	const { setComponent } = useTopPanel()

	useEffect(() => {
		setComponent(<div className="text-base-color text-xl font-medium">Profil sozlamalari</div>)
	}, [])

	return (
		<div>Profile</div>
	)
}

export default Profile