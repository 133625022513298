import useTopPanel from "hooks/useTopPanel"
import React, { useEffect } from "react"

const Message = () => {
	const { setComponent } = useTopPanel()

	useEffect(() => {
		setComponent(<div className="text-base-color text-xl font-medium">SMS</div>)
	}, [])

	return <div>SMS</div>
}

export default Message
