import useAuth from "hooks/useAuth"
import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import ROLE_TYPE_LIST from "shared/roleTypeList"

const LogInSignUpLayout = () => {
	const [{ user }] = useAuth()

	// if (user && user?.user?.role === ROLE_TYPE_LIST.ADMIN.code) {
	// 	return <Navigate to="/admin/dashboard" replace />
	// }

	// if (user && user?.user?.role === ROLE_TYPE_LIST.ACCOUNTER.code) {
	// 	return <Navigate to="/accounter/payment" replace />
	// }

	// if (user && user?.user?.role === ROLE_TYPE_LIST.MANAGER.code) {
	// 	return <Navigate to="/manager/dashboard" replace />
	// }

	// if (user && user?.user?.role === ROLE_TYPE_LIST.OPERATOR.code) {
	// 	return <Navigate to="/operator/lead" replace />
	// }

	return (
		<div className="login-sign-up-wrapper flex md:flex-row flex-col md:items-center justify-center min-h-screen">
			<div className="login-sign-up-img-box md:w-1/2 w-0 md:block hidden min-h-screen">
				<img
					src={require("assets/images/login-reg-bg.jpeg")}
					alt="image2"
					className="min-h-screen object-cover"
				/>
			</div>
			<div className="login-sign-up-enter-box md:w-1/2 w-full xl:px-32 lg:px-24 md:px-16 sm:px-20 p-12 py-10">
				<Outlet />
			</div>
		</div>
	)
}

export default LogInSignUpLayout
