/** @format */

import { useLocation, useNavigate } from 'react-router';
import replaceParams from './replaceParams';

const useSetQuery = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const setQuery = (key, value) => {
    navigate(`${pathname}${replaceParams(key, value)}`);
  };
  return setQuery;
};

export default useSetQuery;
