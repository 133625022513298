/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button } from "@mui/material"
import SearchInput from "components/SearchInput"
import SimpleSearchTable from "components/ui/tables/SimpleSearchTable"
import ArrearFiltersComponent from "components/ui/filters/ArrearFiltersComponent"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import CLIENT_TYPE from "shared/clientTypeList"
import { clientTypeVariants } from "shared/tableColVariantsList"

const Arrears = () => {
	const { setComponent } = useTopPanel()

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				Qarzdorlik ro'yxati
			</div>
		)
	}, [])

	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [expanded, setExpanded] = useState(false)

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full">
						<Button
							variant="filterOutlined"
							color="primary"
							startIcon={<i className="bi bi-filter" />}
							className="!mr-2"
							onClick={() => setExpanded((prev) => !prev)}
							>
							Filtr
						</Button>
						<SearchInput inputKey="name" />
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							className="!mr-2"
							disable={`${refetch}`}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
				{expanded && (
					<div className="my-shadow-2 rounded-lg px-4 w-full mt-2">
						<ArrearFiltersComponent />
					</div>
				)}
			</div>

			<div className="component-table-wrapper">
				<SimpleSearchTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath="dictionary/debitors"
					emitTableColumns={{ open, setOpen }}
					tableName="arrears"
					pathName="arrear"
					replacePathName="contract"
					headCells={[
						{ code: "name", label: "Shartnoma nomi" },
						{ code: "custom", label: "Mijoz" },
						{ code: "sum", label: "Shartnoma summasi" },
						{ code: "type", label: "Shartnoma turi" },
						{ code: "phone", label: "Telefon" },
						{ code: "date", label: "Sana" }
					]}
					columns={[
						{ code: "name", type: "textLimit"},
						{ code: "custom", type: "textLimit" },
						{ code: "sum", type: "textLimit" },
						{ code: "type", type: "textLimit" },
						{ code: "phone", type: "textLimit" },
						{ code: "date", type: "date" },
						{ code: "contract_id", type: "textLimit" },

					]}
					isInfoColumnExist={true}
					infoColumnCode = "contract_id"
				/>
			</div>
		</div>
	)
}

export default Arrears
