function getLabelByTypeList(typeList = {}, code = "") {
	if (typeList && code) {
		let result = Object.keys(typeList).find(
			(item) => typeList[item].code === code
		)
		return typeList[result]?.label
	}
	return ""
}

module.exports = getLabelByTypeList
