/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { LinearProgress, Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import setTableCellType from 'utils/setTableCellType';
import TableColumnToggleDrawer from 'components/TableColumnToggleDrawer';
import InfoActionButton from '../action-buttons/InfoActionButton';

import useColumnToggle from 'hooks/useColumnToggle';
import SimpleTableHead from './parts/SimpleTableHead';
import addActiveInTableCell from 'utils/addActiveInTableCell';
import { thousandsSeparator } from '../../../utils/formatters';

export default function SimpleSearchTable({
  tableTitle,
  dataPath = '',
  headCells = [],
  columns = [],
  tableName = '',
  pathName = '',
  replacePathName = '',
  emitTableColumns = {},
  emitRefetch = {},
  isFilterable = false,
  isInfoColumnExist = false,
  infoColumnCode = '',
}) {
  const axiosPrivate = useAxiosPrivate();
  const { tableData, tableHeadCells, tableColumnCells, setTableHeadCells } =
    useColumnToggle(
      tableName,
      addActiveInTableCell(headCells),
      addActiveInTableCell(columns)
    );
  const [queryPath, setQueryPath] = React.useState('');

  const [hasError, setHasError] = React.useState(false);
  const {
    error,
    data: rows,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: dataPath + 'Key',
    queryFn: async function () {
      const response = await axiosPrivate.get(queryPath);
      return response?.data;
    },
    onSettled: () => {
      emitRefetch.setRefetch(false);
    },
    enabled: false,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    (searchParams.get('page') && parseInt(searchParams.get('page'))) || 1
  );
  React.useEffect(() => {
    if (queryPath && queryPath.length > 0) {
      refetch();
    }
  }, [queryPath]);

  React.useEffect(() => {
    createQueryPath();
  }, [searchParams]);

  React.useEffect(() => {
    if (emitRefetch && emitRefetch.refetch) {
      refetch();
    }
  }, [emitRefetch.refetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  const createQueryPath = () => {
    let newQueryPath = `/${dataPath}`;
    let localSearchParams = Object.fromEntries([...searchParams]);
    Object.keys(localSearchParams).forEach((item, index) => {
      if (index === 0) {
        newQueryPath += `?${item}=${localSearchParams[item]}`;
      } else {
        newQueryPath += `&${item}=${localSearchParams[item]}`;
      }
    });
    setQueryPath(newQueryPath);
    if (!isNaN(localSearchParams.page)) {
      setPage(parseInt(localSearchParams.page));
    }
  };
  const { contract, payment } = rows?.data || { contract: [], payment: [] };
  const reportsDataList = payment?.map((item, i) => {
    const sum = thousandsSeparator(Math.floor(item?.sum));
    const { name, surname } = item?.contract?.custom || {};
    const sum2 = thousandsSeparator(Math.floor(contract?.[i]?.sum) || null);
    const paymentSum = item?.isvalute === '1' ? `${sum} $` : `${sum} UZS`;
    const contractSum = sum2
      ? contract?.[i]?.isvalute === '1'
        ? `${sum2} $`
        : `${sum2} UZS`
      : null;
    const { name: name2, surname: surname2 } = contract?.[i]?.custom || {};
    return {
      id: i + 1,
      paymentOwner: name ? name + ' ' + surname + ' - ' + paymentSum : null,
      contractOwner: name2
        ? name2 + ' ' + surname2 + ' - ' + contractSum
        : null,
    };
  });
  return (
    <Box className='base-table w-full h-full flex flex-col'>
      <TableContainer className='flex-auto h-full'>
        <Table
          stickyHeader
          sx={{ minWidth: 750, height: 'max-content' }}
          aria-labelledby='tableTitle'
        >
          {tableHeadCells && tableColumnCells && (
            <React.Fragment>
              <SimpleTableHead
                headCells={tableHeadCells}
                isFilterable={isFilterable}
                contractcount={rows?.data?.contractcount}
                countpayment={rows?.data?.countpayment}
                total={rows?.data?.total}
              />
              {isLoading || isFetching ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : isError ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length}>
                      <div className='flex flex-col items-center'>
                        {error?.response?.data?.message && (
                          <span className='text-red-600 font-medium'>
                            {error?.response?.data?.message}
                          </span>
                        )}
                        {error?.response?.data?.details &&
                          error?.response?.data?.details[0]?.message && (
                            <div>
                              <span className='text-red-600 font-medium'>
                                Error message:{' '}
                              </span>
                              <span>
                                {error?.response?.data?.details[0]?.message}
                              </span>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : rows && rows.data && rows.data.length > 0 ? (
                <React.Fragment>
                  <TableBody className='overflow-hidden'>
                    {rows.data.map((row, rowIndex) => {
                      return (
                        <TableRow hover tabIndex={-1} key={'row-' + rowIndex}>
                          {tableColumnCells.map((column, columnIndex) => (
                            <TableCell
                              align={
                                tableHeadCells[columnIndex].numeric
                                  ? 'right'
                                  : 'left'
                              }
                              key={'column-' + columnIndex}
                            >
                              {column.type
                                ? setTableCellType(
                                    column,
                                    row[column.code],
                                    row
                                  )
                                : row[column.code]}
                            </TableCell>
                          ))}
                          {isInfoColumnExist ? (
                            <TableCell align={'left'}>
                              <InfoActionButton
                                link={`/view/${row[infoColumnCode]}`}
                                isReplace={true}
                                replace_url={pathName}
                                replace_str={replacePathName}
                              />
                            </TableCell>
                          ) : (
                            ''
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </React.Fragment>
              ) : tableTitle === 'Jami hisobotlar' ? (
                <React.Fragment>
                  <TableBody className='overflow-hidden'>
                    {reportsDataList?.map((row, rowIndex) => {
                      return (
                        <TableRow hover tabIndex={-1} key={'row-' + rowIndex}>
                          {tableColumnCells.map((column, columnIndex) => (
                            <TableCell
                              align={
                                tableHeadCells?.[columnIndex]?.numeric
                                  ? 'right'
                                  : 'left'
                              }
                              key={'column-' + columnIndex}
                            >
                              {column?.type
                                ? setTableCellType(
                                    column,
                                    row?.[column.code],
                                    row
                                  )
                                : row?.[column?.code]}
                            </TableCell>
                          ))}
                          {!isInfoColumnExist ? (
                            <TableCell align={'left'}>
                              <InfoActionButton
                                link={`/view/${row[infoColumnCode]}`}
                                isReplace={true}
                                replace_url={pathName}
                                replace_str={replacePathName}
                              />
                            </TableCell>
                          ) : (
                            ''
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </React.Fragment>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length}>
                      <span className='no-data-found-wrapper'>
                        <i className='bi bi-exclamation-octagon text-xl mr-1' />{' '}
                        Ma'lumotlar topilmadi!
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
      {rows && rows.data && rows.data.length > 0 && (
        <div className='p-3 mb-2 mt-4 flex items-center justify-center rounded-lg my-shadow-2'>
          <Pagination
            count={Math.ceil(rows.total / rows.per_page) || 1}
            page={page}
            onChange={handleChangePage}
            variant='outlined'
            color='primary'
            showFirstButton
            showLastButton
          />
        </div>
      )}

      {emitTableColumns && Object.keys(emitTableColumns).length === 2 && (
        <TableColumnToggleDrawer
          open={emitTableColumns.open}
          setOpen={emitTableColumns.setOpen}
          tableData={tableData}
          setTableHeadCells={setTableHeadCells}
        />
      )}
    </Box>
  );
}
