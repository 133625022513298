import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from "@mui/material"
import { Box } from "@mui/system"
import React, { Fragment } from "react"
import { visuallyHidden } from "@mui/utils"

const BaseTableHead = (props) => {
	const { order, orderBy, onRequestSort, headCells, isFilterable, extra_column, hasSelect, allSelected, setAllSelected} = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{hasSelect &&  <TableCell padding="normal" align="left" sx={{ maxWidth: 40}}>
				<Checkbox
					id={"checkboxall"}
					checked={allSelected}
					onChange={(event) => {
						setAllSelected(event.target.checked)
					}}
				/>
				</TableCell>
				}
				{headCells.map((headCell, headCellIndex) => (
					<Fragment key={"head-cell-" + headCell.code}>
						{isFilterable ? (
							<TableCell
								align={headCell?.numeric ? "right" : "left"}
								padding={headCell?.disablePadding ? "none" : "normal"}
								sortDirection={orderBy === headCell.code ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.code}
									direction={orderBy === headCell.code ? order : "asc"}
									onClick={createSortHandler(headCell.code)}
									hideSortIcon={orderBy === headCell.code}
								>
									<div dangerouslySetInnerHTML={{ __html: headCell?.label }} />
									{orderBy === headCell.code ? (
										<Box component="span" sx={visuallyHidden}>
											{order === "desc"
												? "sorted descending"
												: "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell
								align={headCell?.numeric ? "right" : "left"}
								padding={headCell?.disablePadding ? "none" : "normal"}
							>
								<div dangerouslySetInnerHTML={{ __html: headCell?.label }} />
							</TableCell>
						)}
					</Fragment>
				))}
				{extra_column !== false && <TableCell padding="normal" align="right">
					Amallar
				</TableCell>}
				
			</TableRow>
		</TableHead>
	)
}

export default BaseTableHead
