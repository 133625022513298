const CLIENT_TYPE = {
	PHYSICAL: {
		code: "1",
		label: "Jismoniy shaxs"
	},
	LEGAL: {
		code: "2",
		label: "Yuridik shaxs"
	}
}

module.exports = CLIENT_TYPE
