const ORDER_TYPE = {
	NEW: {
		code: "1",
		label: "Faol"
	},
	ORDERED: {
		code: "2",
		label: "Shartnoma qilingan"
	},
	CANCELLED: {
		code: "3",
		label: "Bekor qilingan"
	}
}

module.exports = ORDER_TYPE
